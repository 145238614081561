$(function() {
  // Detect device & browser
  $(window).bind('load resize', function() {

    // BROWSER CLASS
    if (navigator.userAgent.indexOf('Safari') != -1 && navigator.userAgent.indexOf('Chrome') == -1) {
        $("body").addClass("browser-safari");
    }
    if (navigator.userAgent.match(/iP(hone|od|ad)/i)) {
        $("body").removeClass("browser-safari");
        jQuery('body').addClass('browser-ios');
    }

    // Get browser
    $.each($.browser, function(i) {
        $('html').addClass(i);
        return false;
    });
    // Get OS
    var os = [
        'iphone',
        'ipad',
        'windows',
        'mac',
        'linux'
    ];
    var match = navigator.appVersion.toLowerCase().match(new RegExp(os.join('|')));
    if (match) {
        $('html').addClass(match[0]);
    };

    let navigationHeight = $('.navigation').innerHeight();
    if ($(window).width() < 992) {
        $('.navigation').css('transform', 'translateY(-' + (1 / 16) * navigationHeight + 'rem)');
    }else {
        $('.navigation').css('transform', 'translateY(0)');
    }

  });

  // FIX PARALLAX
  // IE 11 background-attachment:fixed will jitter on scroll if this is missing
  jQuery(function() {
    if (navigator.userAgent.match(/MSIE 10/i) || navigator.userAgent.match(/Trident\/7\./) || navigator.userAgent.match(/Edge\/12\./)) {
        $('body').on("mousewheel", function() {
            event.preventDefault();
            var wd = event.wheelDelta;
            var csp = window.pageYOffset;
            window.scrollTo(0, csp - wd);
        });
    }
  });

  // ADD CLASS TO HTML ELEMENT WHEN THE MOBILE/BURGER NAVIGATION IS PRESENT
	(function($) {
    var $window = $(window),
        $html = $('html');

    function resize() {
        if ($window.width() < 992) {
            return $html.addClass('mobile-width');
        }
        $html.removeClass('mobile-width');
    }
    $window
        .resize(resize)
        .trigger('load resize');
  })(jQuery);


  // Main Navigation
  $(document).mouseup(function(e) {
    const container = $("#site-header"); /* Assign outside which element must be clicked */
    if (!container.is(e.target) && container.has(e.target).length === 0) {
        $("html").removeClass('reveal-out');
        $("header#site-header button.expand").removeClass('active');
    }
  });

    // EXPAND MOBILE NAVIVAGTION
    $("header#site-header button.expand").click(function() {
        $('.navigation').css('opacity', '1');

        if ($(".navigation .reveal").length) {
            $("header#site-header button.expand").toggleClass('active');
            $("html").toggleClass('reveal-out');
        } else {
            $("header#site-header button.expand").toggleClass('active');
            $("html").toggleClass('reveal-out');
        }
    });

  // SCROLLING ANIMATION
    $(window).scroll(function(){

        var winheight1 = $(window).height()/100*30
        var docheight1 = $(document).height()/100*30
        var winheight2 = $(window).height()/100*21
        var docheight2 = $(document).height()/100*21
        var scrollTop = $(window).scrollTop()
        var trackLength1 = docheight1 - winheight1
        var trackLength2 = docheight2 - winheight2
        var pctScrolled1 = Math.round(scrollTop/trackLength1 * 100) / 100
        var pctScrolled2 = Math.round(scrollTop/trackLength2 * 100) / 100


        var $scrollPercentBG = pctScrolled1;
        if($scrollPercentBG <= 0.85){
            $('header').css({backgroundColor:'rgba(32,31,51,'+$scrollPercentBG+')'});
        }

        var $scrollPercentBC = 0.1 + (pctScrolled2/30);
        if($scrollPercentBC <= 0.15){
            $('header').css({borderColor:'rgba(255,255,255,'+$scrollPercentBC+')'});
        }
        if($scrollPercentBC <= 0.1){
            $('header').css({borderColor:'rgba(255,255,255,0.1)'});
        }

        $(".header-image-wrapper").css({
            transform: 'translate3d(0px, -'+(scrollTop/43.5)+'rem, 0px) scale3d(1, 1, 1) rotateX(0deg) rotateY(0deg) rotateZ(0deg) skew(0deg, 0deg)'
        });
    });

    // SUB Navigation
    $('.nav-dropdown').on("click", function(){
        $(this).toggleClass('dropdown-open');
        if ($(this).find(".nav-dropdown-toggle").attr('aria-expanded', "true")) {
            $(this).find(".nav-dropdown-toggle").attr('aria-expanded', "false");
        } else {
            $(this).find(".nav-dropdown-toggle").attr('aria-expanded', "true");
        }
    })
    $('.nav-dropdown').on("mouseover", function(){
        $(this).addClass('dropdown-open');
        $(this).find(".nav-dropdown-toggle").attr('aria-expanded', "true");
    })
    $('.nav-dropdown').on("mouseout", function(){
        $(this).removeClass('dropdown-open');
        $(this).find(".nav-dropdown-toggle").attr('aria-expanded', "false");
    })

    accessible_submenu();

  // Partners collapse
    $('.partners').on('show.bs.collapse', function (e) {
        $(e.target).parent('.card').addClass('is-open');
    })
    $('.partners').on('hide.bs.collapse', function (e) {
        $(e.target).parent('.card').removeClass('is-open');
    })

  // Accordion
  let $accordion = $('.accordion');
  let $openBtn = $accordion.find('.btn.closed');
  let $btnWrapper = $accordion.find('.buttons-wrapper');

  $('.accordion').on('show.bs.collapse', function (e) {
      $(e.target).parent('.session').addClass('is-open');

      let totalItems = $('.accordion').find('.session').length;
      let totalItemsOpen = $('.accordion').find('.session.is-open').length;


      if (totalItems == totalItemsOpen) {
          $btnWrapper.addClass('is-open');
          $openBtn.removeClass('btn-success closed');
          $openBtn.addClass('btn-danger open');
          $openBtn.attr("title","Close all");
          $openBtn.find('.open-btn').addClass('d-none');
          $openBtn.find('.close-btn').removeClass('d-none');
      }

    let $button_more = $(e.target).parent('.session').find(".more-info .session-btn");
    let $less =  $button_more.attr("data-less");

    $button_more.find("div:last-child").empty();
    $button_more.find("div:last-child").html($less);
  })
  $('.accordion').on('hide.bs.collapse', function (e) {
      $(e.target).parent('.session').removeClass('is-open');

      $btnWrapper.removeClass('is-open');
      $btnWrapper.find('.btn').addClass('btn-success closed');
      $btnWrapper.find('.btn').removeClass('btn-danger open');
      $btnWrapper.find('.btn').attr("title","Open all");
      $btnWrapper.find('.btn').find('.open-btn').removeClass('d-none');
      $btnWrapper.find('.btn').find('.close-btn').addClass('d-none');

      let $button_more = $(e.target).parent('.session').find(".more-info .session-btn");
      let $more =  $button_more.attr("data-more");

      $button_more.find("div:last-child").empty();
      $button_more.find("div:last-child").html($more);

  })

  // Open and Close All
  $('body').on('click', '.btn.closed', function(e) {
          $('.accordion').find('.collapse').collapse('show');
          $('.accordion').find('.session').addClass('is-open');
          $('.accordion').find('.buttons-wrapper').addClass('is-open');
          $(this).removeClass('btn-success closed');
          $(this).addClass('btn-danger open');
          $(this).attr("title","Close all");
          $(this).find('.open-btn').addClass('d-none');
          $(this).find('.close-btn').removeClass('d-none');
  });
  $('body').on('click', '.btn.open', function(e) {
        $('.accordion').find('.collapse').collapse('hide');
        $('.accordion').find('.session').removeClass('is-open');
        $('.accordion').find('.buttons-wrapper').removeClass('is-open');
        $(this).addClass('btn-success closed');
        $(this).removeClass('btn-danger open');
        $(this).attr("title","Open all");
        $(this).find('.open-btn').removeClass('d-none');
        $(this).find('.close-btn').addClass('d-none');
   });

  accessible_bootstrap_accordion();

$('body').on('click', '.btn-modal', function(e) {
    e.preventDefault();
    e.stopPropagation();
    var popupID = $(this).data('target');

    $(popupID).modal('show');
});

// Quick Session register
$('body').on('click', '.session-register-btn', function(e) {
    e.preventDefault();
    e.stopPropagation();
    var urlRequest = $(this).data("service");
    var sessionKey = $(this).data("key");
    var popupID = $(this).parents(".modal").attr("id");
    var datas = "";
    var currentUrl = $(this).data("callback");
    var $data;
    var $htmlTarget = $(".agenda #results");

    datas += "sessions=" + sessionKey;

    $.ajax({
        url: urlRequest,
        type: "GET",
        traditional: true,
        async: false,
        cache: false,
        data: datas,
        dataType: "json",
        contentType: "application/json; charset=utf-8",
        success: function (data) {
                // Close popup
                $('#' + popupID).modal('hide');
                $('body').removeClass('modal-open');
                $(".modal-backdrop").remove();

                //Refresh agenda
                $.get(currentUrl, function (data) {
                    $data = $(data).find(".agenda #results");
                    $htmlTarget.html($data.html());

                    $(".session-register-btn").attr("data-callBack", currentUrl);
                })

        },
        failure: function (msg) {
            alert("Error to execute action. Please retry.");
        }
    });

});


// Cancel account
$('body').on('click', '.cancel-account-btn', function(e) {
    e.preventDefault();
    e.stopPropagation();
    var urlRequest = $(this).data("service");
    var popupID = $(this).parents(".modal").attr("id");
    var datas = "Status=§Cancelled";

    $.ajax({
        url: urlRequest,
        type: "GET",
        traditional: true,
        async: false,
        cache: false,
        data: datas,
        dataType: "json",
        contentType: "application/json; charset=utf-8",
        success: function (data) {
                // Close popup
                $('#' + popupID).modal('hide');
                $('body').removeClass('modal-open');
                $(".modal-backdrop").remove();

                //Logout redirect
                window.location.href = "/";

        },
        failure: function (msg) {
            alert("Error to execute action. Please retry.");
        }
    });

});


  // Flex speaker avatar agenda
  var speakers = $('.card-speakers-sum'); //select flex wrap and wrap-reverse elements

    if (speakers.length) { //don't add listener if no flex elements
        $(window)
            .on('resize', function() {
                speakers.each(function() {
                    var prnt = $(this),
                        chldrn = prnt.children(':not(:first-child)'), //select flex items
                        frst = prnt.children().first();

                    if (chldrn.length > 0) {
                        chldrn.each(function(i, e) { $(e).toggleClass('flex-wrapped invisible', $(e).offset().top != frst.offset().top); }); //element has wrapped
                        prnt.toggleClass('flex-wrapping', !!frst.find('.flex-wrapped').length); //wrapping has started
                        frst.toggleClass('flex-wrapped', !!!chldrn.filter(':not(.flex-wrapped)').length); //all are wrapped
                    }

                    if(prnt.find('.flex-wrapped').length) {
                        var $addedDiv = prnt.find(".speaker.speaker--more");

                        if ($addedDiv.length == 0) {
                            prnt.prepend('<div class="speaker speaker--more"></div>');
                            addCount(prnt);
                        }else {
                            addCount(prnt);
                        }
                    }else {
                        prnt.find(".speaker.speaker--more").remove();
                    }

                    function addCount(prnt) {
                        var count = prnt.find('.flex-wrapped').length;
                        prnt.find(".speaker.speaker--more").html("+"+ count);
                    }
            });
            })
            .trigger('resize'); //lazy way to initially call the above
    }


// Banner spacer
let bannerText = $('.banner').find('.text').length;
let bannerlink = $('.banner').find('.text').length;

if(bannerText == 0 && bannerlink == 0) {
    $('.banner .heading').addClass('mb-0')
}


  // Live select
  (function () {
        var previous;

        $("#live-select").on('focus', function () {
            // Store the current value on focus and on change
            previous = this.value;
        }).change(function(e) {
            var live = this.value;
            e.stopPropagation();

            $('.' + previous).addClass('d-none');
            $('.' + live).removeClass('d-none');

            var iFrame = $('.' + previous).find('iframe[src*="vimeo.com"]');
            var iFrameSRC = iFrame.attr('src');
            iFrame.attr('src','');
            iFrame.attr('src', iFrameSRC);


            // Make sure the previous value is updated
            previous = this.value;
        });
    })();


    // Alert close Bootstrap
    $('.alert').alert();

    // view more/less agenda
    $.each($(".card.session"), function () {
        let $this = $(this);
        let $button = $this.find(".more-info .session-btn");
        let $more =  $button.attr("data-more");

        $button.find("div:last-child").html($more);

    });

    // Bootstrap open tab from url hash
    var hash = location.hash.replace(/^#/, '');  // means starting, meaning only match the first hash
    if (hash) {
        $('.nav-tabs a[href="#' + hash + '"]').tab('show');
    }

    // Change hash for page-reload
    $('.nav-tabs a').on('shown.bs.tab', function (e) {
        window.location.hash = e.target.hash;
    })

});

// Tooltip
$(function () {
  if(isTouchDevice()===false) {
    $('body').tooltip({selector: '[data-toggle="tooltip"]'});
  }
})

// Popover
$(function () {
    $('body').popover({selector: '[data-toggle="popover"]'});
    $('.popover-short-description').popover({trigger: 'hover'});

    $.each($(".popover-short-description"), function () {
        let dataContent = $(this).attr("data-content");
        $(this).attr("data-content",dataContent.replaceAll("&nbsp", " ") );

        let iconClass =  $(this).find("i").attr("class");
        $(this).find("i").attr("class",iconClass.replaceAll("&nbsp", " ") );
    });
})

// Improve scroll performance of animation
function debounce(func, wait, immediate) {
  var timeout;
  return function() {
      var context = this,
          args = arguments;
      var later = function() {
          timeout = null;
          if (!immediate) func.apply(context, args);
      };
      var callNow = immediate && !timeout;
      clearTimeout(timeout);
      timeout = setTimeout(later, wait);
      if (callNow) func.apply(context, args);
  };
};
var myEfficientScroll = debounce(function() {
  // Trigger animations
  // http://www.oxygenna.com/tutorials/scroll-animations-using-waypoints-js-animate-css
  function onScrollInit(items, trigger) {
      items.each(function() {
          var osElement = $(this),
              osAnimationClass = osElement.attr('data-os-animation'),
              osAnimationDelay = osElement.attr('data-os-animation-delay');
          osElement.css({
              '-webkit-animation-delay': osAnimationDelay,
              'animation-delay': osAnimationDelay
          });
          var osTrigger = (trigger) ? trigger : osElement;
          osTrigger.waypoint(function() {
              osElement.addClass('animate__animated').addClass(osAnimationClass);
          }, {
              triggerOnce: true,
              offset: '90%'
          });
      });
  }
  onScrollInit($('.os-animation'));
}, 250);
window.addEventListener('load', myEfficientScroll);

// Accessible  -  Define values for keycodes
function keyCodes() {
    this.tab = 9;
    this.untab = 16;
    this.enter = 13;
    this.escape = 27;
    this.space = 32;
    this.end = 35;
    this.home = 36;
    this.left = 37;
    this.up = 38;
    this.right = 39;
    this.down = 40;
}

// Accessible - bootstrap accordion
function accessible_bootstrap_accordion() {
    var keys = new keyCodes();

    // Tabs nav links
    $(document).on('keydown', '.accordion [data-toggle="collapse"]', function (e) {
      var currentBtn = $(this),
          currentCardWrapper = currentBtn.parents('.card');

      function next() {
        $(currentCardWrapper.next().find('[data-toggle="collapse"]').focus().attr('data-target'));
      }
      function prev() {
        $(currentCardWrapper.prev().find('[data-toggle="collapse"]').focus().attr('data-target'));
      }

      if (e.which === keys.enter || e.which === keys.space) {
        e.preventDefault(); // Disable arrow key scrolling
        $(currentBtn.attr('data-target')).collapse('toggle');
      }

      if (e.which === keys.down) {
        e.preventDefault(); // Disable arrow key scrolling
        $(currentBtn.attr('data-target')).collapse('hide');

        if (currentCardWrapper.next('.card').length) {
            next();
        }
      }

      if (e.which === keys.up) {
        e.preventDefault(); // Disable arrow key scrolling
        $(currentBtn.attr('data-target')).collapse('hide');

        if (currentCardWrapper.prev('.card').length) {
            prev();
        }
      }

    });
  }

  function accessible_submenu() {
    var keys = new keyCodes();

    $(document).on('keydown', '.nav-dropdown', function (e) {
        if ((e.which === keys.enter || e.which === keys.space) && !$(this).find('.nav-dropdown-list a').is(':focus')) {
            e.preventDefault(); // Disable arrow key scrolling
            $(this).toggleClass('dropdown-open');
            if ($(this).find(".nav-dropdown-toggle").attr('aria-expanded') == "false") {
                $(this).find(".nav-dropdown-toggle").attr('aria-expanded', "true");
            }else {
                $(this).find(".nav-dropdown-toggle").attr('aria-expanded', "false");
            }
        }

        if (e.which === keys.down) {
            e.preventDefault(); // Disable arrow key scrolling
            if ($(this).find('.nav-dropdown-list a').is(':focus')) {
                $(this).find('.nav-dropdown-list a:focus').nextAll("a:first").focus();
            } else {
                $(this).find('.nav-dropdown-list a:first-of-type').focus();
            }
        }

        if (e.which === keys.up) {
            e.preventDefault(); // Disable arrow key scrolling
            if ($(this).find('.nav-dropdown-list a').is(':focus')) {
                $(this).find('.nav-dropdown-list a:focus').prevAll("a:first").focus();
            } else {
                $(this).find('.nav-dropdown-list a:first-of-type').focus();
            }
        }
    });
  }


// Is Touche Device ?
function isTouchDevice(){
  return true == ("ontouchstart" in window || window.DocumentTouch && document instanceof DocumentTouch);
}


// Favorites
var love = function () {
    $('body').on('click', '.btn--favourites', function(e) {
        e.preventDefault();

        var $this = $(this);
        var $added = $this.find(".far");
        var $removed = $this.find(".fas");
        var $add_title = $this.attr("data-added");
        var $remove_title = $this.attr("data-removed");

        $.get($this.attr("href"), function (data) {

            if($added.length > 0) {
                $added.removeClass("far").addClass("fas");
                $this.attr("title", $remove_title);
                $this.find("span").html($remove_title);
            }
            if($removed.length > 0) {
                $removed.removeClass("fas").addClass("far");
                $this.attr("title", $add_title);
                $this.find("span").html($add_title);
            }

        });

    });
};
love();